<template>
  <div>
    <validation-observer
        ref="refFormObserver"
    >
      <validation-provider
          #default="validationContext"
          name="Full Name"
          rules="required"
      >
        <b-form-group :label="$t('message.Name')" for="name">
          <b-form-input autofocus v-model="tickets.name"></b-form-input>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider #default="validationContext" :name="$t('message.Fly_datetime')" rules="required" class="w-100 mt-1">
        <label for="fly_datetime">{{$t('message.Fly_datetime')}}</label>
        <input type="date" class="form-control mb-2" v-model="tickets.fly_datetime">
        <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
      </validation-provider>

      <validation-provider #default="validationContext" :name="$t('message.Arrive_datetime')" rules="required" class="w-100 mt-1">
        <label for="arrive_datetime">{{$t('message.Arrive_datetime')}}</label>
        <input type="date" class="form-control mb-2" v-model="tickets.arrive_datetime">
        <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
      </validation-provider>

      <validation-provider
          #default="validationContext"
          name="Limit"
          rules="required"
      >
        <b-form-group :label="$t('message.Limit')">
          <b-form-input autofocus v-model="tickets.limit"></b-form-input>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
          #default="validationContext"
          name="Type"
          rules="required"
      >
        <b-form-group :label="$t('message.Type')">
          <b-form-input autofocus v-model="tickets.type"></b-form-input>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <div class="w-100 d-flex justify-content-end mt-3">
        <b-button variant="primary" @click="handleSubmit" :disabled="disabled">
          {{$t('message.Save')}}
        </b-button>
        <b-button variant="warning" class="ml-1" @click="$emit('closeModal')">
          {{$t('message.Cancel')}}
        </b-button>
      </div>

    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import { addTickets, editTickets, getTicketsById } from '@/api/avia-tickets'

export default {
  name: 'TicketsForm',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props:{
    id:{
      default:null
    }
  },
  data() {
    return {
      required,
      alphaNum,
      tickets: {
        id: null,
        name: '',
        fly_datetime:'',
        arrive_datetime:'',
        limit:'',
        type:''
      },
      disabled: false,
    }
  },
  methods: {
    handleSubmit() {
      const isValid = this.$refs.refFormObserver.validate();
      this.disabled = true;
        setTimeout(() => {
          this.disabled = false;
        }, 1000)
      if (isValid) {
        if (this.id) {
          this.tickets.id = this.id;
          // eslint-disable-next-line no-unused-vars
          editTickets(this.tickets).then(res => {
            this.$emit('closeModal')
          })
        } else {
          // eslint-disable-next-line no-unused-vars
          addTickets(this.tickets).then(res => {
            this.$emit('closeModal', res.data.data)
          })
        }
      }
    },
    fetchTicketsById() {
      getTicketsById(this.id).then(res => {
        this.tickets = res.data.data;
      })
    }
  },
  watch: {
    id:{
      immediate: true,
      handler(val) {
        if(val) {
          this.fetchTicketsById()
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
