<template>
  <div>
    <div class="w-100 d-flex justify-content-end">
      <b-button variant="primary" class="mb-1" @click="handleAdd">
        <b-icon icon="plus"></b-icon>{{$t('message.Add')}}
      </b-button>
    </div>
    <b-card>
      <b-table
          :fields="fields"
          :items="tickets"
          responsive
          :busy="busy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(action)="data">
          <div class="d-flex">
            <div>
              <b-button
                  variant="outline-warning"
                  size="sm"
                  @click="handleEdit(data.item.id)">
                <b-icon icon="pencil"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button
                  variant="outline-danger"
                  size="sm"
                  @click="handleDelete(data.item.id)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </template>
      </b-table>
    </b-card>

    <b-pagination
      v-model="pagination.page"
      :total-rows="total"
      :per-page="pagination.pageSize"
      @input="fetchTickets"
    ></b-pagination>
    <b-modal
      hide-footer
      v-model="formModalActive"
      no-close-on-backdrop
    >
      <TicketsForm
        :id="id"
        @closeModal="closeModal"
      />
    </b-modal>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import TicketsForm from '@/views/directories/avia-tickets/components/TicketsForm'
import { deleteTickets, getTickets } from '@/api/avia-tickets'

export default {
  name: 'Index',
  components: { TicketsForm},
  data() {
    return {
      id: null,
      fields: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'name',
          label: this.$t('message.name_title')
        },
        {
          key: 'fly_datetime',
          label: this.$t('message.Fly_datetime')
        },
        {
          key:"arrive_datetime",
          label: this.$t('message.Arrive_datetime')
        },
        {
          key:'limit',
          label: this.$t('message.Limit')
        },
        {
          key:'type',
          label: this.$t('message.Type')
        },
        {
          key: 'action',
          label: this.$t('message.Action')
        },
      ],
      tickets:[],
      formModalActive: false,
      pagination: {
        page: 1,
        pageSize: 20
      },
      total: 1,
      busy: true,
    }
  },
  created() {
    this.fetchTickets()
  },
  methods:{
    fetchTickets(){
      getTickets(this.pagination).then(response => {
        this.tickets = response.data.data
        this.total = response.data.total;
        this.busy = false;
      })
    },
    handleAdd(){
      this.id = null;
      this.formModalActive = true
    },
    handleEdit(id){
      this.id = id;
      this.formModalActive = true
    },
    closeModal(){
      this.formModalActive = false;
      this.fetchTickets()
    },
    handleDelete(id) {
      if (id) {
        Swal.fire({
          title: this.$t('areYouSure'),
          text: this.$t('wontReturn'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('YesDelete'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            // eslint-disable-next-line no-unused-vars
            deleteTickets({
              id
            }).then(res => {
              Swal.fire({
                icon: 'success',
                position: 'center',
                title: this.$t('Deleted'),
                text: this.$t('dataHasDeleted'),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.fetchTickets()
            })
          } else if (result.dismiss === 'cancel') {
            Swal.fire({
              title: this.$t('Cancelled'),
              text: this.$t('yrDataSafe'),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
      }
    },
  }
}
</script>

<style scoped>

</style>
